import logo from './logo.svg';
import './App.css';

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import React, { useState, useEffect } from 'react';
import useAxiosWithCredentials from './hooks/useAxiosWithCredentials';



function App({ signOut, user }) {
  const axiosWithCredentials = useAxiosWithCredentials()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    homeAddress: '',
    dateOfBirth: '',
    email: '',
  });
  useEffect(() => {
    // This effect runs once on mount to load data
    loadData();
  }, []);

  const loadData = async () => {
    // Stub: Replace this with actual data fetching logic
    
    try{
        const response =  await axiosWithCredentials.get(
            '/users/me',
            {});
        console.log(JSON.stringify(response?.data))
        const fetchedData = {
        firstName: response?.data?.firstName,
        lastName: response?.data?.lastName,
        homeAddress: response?.data?.homeAddress,
        email: response?.data?.email,
        dateOfBirth: response?.data?.dateOfBirth,
        ssn: response?.data?.ssn,
      };
  
      setFormData(fetchedData);
        }
        catch(err){
          console.log(err)
    }
        
    
  };



  return (

    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome {formData.username}, This is an Authenticated Arcault
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default withAuthenticator(App);