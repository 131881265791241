import { useEffect } from "react";
import { axiosWithCredentials} from "../api/axios";
import { fetchAuthSession } from 'aws-amplify/auth';



const useAxiosWithCredentials = () => {
    
    useEffect(() => {
        
        const requestIntercept = axiosWithCredentials.interceptors.request.use(
            async config => {
                const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
                if (idToken) {
                    config.headers['Authorization'] = `Bearer ${idToken}`;
                }
                return config;
            },(error) => Promise.reject(error)
        );
        
        
        const responseIntercept = axiosWithCredentials.interceptors.response.use(
            response => response,
            error => {
                // Handle error response
                return Promise.reject(error);
            }
        );

        return () => {
            axiosWithCredentials.interceptors.request.eject(requestIntercept);
            axiosWithCredentials.interceptors.response.eject(responseIntercept);
        };

    }, []);

    return axiosWithCredentials;
}

export default useAxiosWithCredentials;