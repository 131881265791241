const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:d29c54fa-3a1a-4371-9a10-d65a7e13bef8',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_HnIqlDAaj',
    aws_user_pools_web_client_id: '2t2635ic966dhpgq56pshlqdda',
    "oauth": {
        "domain": "https://lifeplan77a1b1a5-77a1b1a5-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "test-logout-request-redirect",
        "redirectSignOut": "test-logout-request-redirect",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};
export default awsmobile;

